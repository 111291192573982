<template>
	<div>
		<top-bar
			:phone="defaults[locale].website.phone"
			:email="defaults[locale].website.email"
			:navigation="langmenu"
		/>
		<nav class="main-nav delay fix-on-scroll" :class="{ 'show-mobile-nav': showMobileNav }">
			<div class="row">
				<div class="columns column12">
					<div class="nav-bar">
						<nuxt-link
							class="logo"
							:to="{ path: localePath('/', locale), hash: route.name.startsWith('index') && '#top' }"
						/>
						<div class="nav-content">
							<ul>
								<li class="menu-close" @click="hideMobileNav">
									<font-awesome-icon icon="fa-light fa-times" size="1x" />
								</li>
								<li v-for="item in topmenu[locale]" :key="item.filename" class="nav-item">
									<span @click="hideMobileNav">
										<nuxt-link :to="item.filename">
											{{ item.header }}
										</nuxt-link>
									</span>
									<submenu
										v-if="item.submenu.length"
										:subitems="item.submenu"
										@hide-mobile-nav="hideMobileNav"
									/>
								</li>
								<!--<li class="lang-nav mobile-only">
									<div v-for="item in langmenu" :key="item.filename" class="language">
										<nuxt-link
											class="flag"
											:class="item.language"
											:to="item.filename"
											@click="hideMobileNav"
										>
											<span>{{ $t(item.language) }}</span>
										</nuxt-link>
									</div>
								</li>-->
							</ul>
						</div>
						<div class="buttons">
							<!-- <div v-click-outside="closeLangNav" class="nav-buttons">
								<div v-if="langmenu.length" class="lang-select fixed-lang-nav" @click="toggleLangNav">
									<font-awesome-icon icon="fa-light fa-globe" size="1x" />
									<span>{{ locale }}&nbsp;</span>
									<font-awesome-icon icon="fa-light fa-chevron-down" size="1x" />
									<div v-if="showLangNav" class="lang-wrapper">
										<div v-for="item in langmenu" :key="`${item.filename}-${item.language}`">
											<nuxt-link
												class="flag"
												:class="item.language"
												:to="`${item.filename}`"
												@click="hideMenu"
											>
												{{ item.language }}
											</nuxt-link>
										</div>
									</div>
								</div>
							</div> -->
							<div class="menu-button button mobile-only" @click="toggleMobileNav">
								<font-awesome-icon class="icon" icon="fal fa-bars" size="1x" />
							</div>
							<a
								class="button phone cta mobile-only"
								:href="`tel:${defaults[locale].website.phone}`"
								aria-label="Call us"
							>
								<font-awesome-icon class="icon" icon="fal fa-phone" size="1x" />
							</a>
							<div class="button book-now cta reserve-now" @click="toggleBookPopup">
								<font-awesome-icon class="icon" icon="fal fa-calendar-alt" size="1x" />
								<span>{{ defaults[locale].website.book }}</span>
							</div>
							<!-- <book-button class="button book-now cta reserve-now">
								<font-awesome-icon class="icon" icon="fal fa-calendar-alt" size="1x" />
								<span>{{ defaults[locale].website.book }}</span>
							</book-button> -->
						</div>
					</div>
				</div>
			</div>
		</nav>
		<div class="book-popup" :class="{ 'show-book-popup': showBookPopup }">
			<div class="popup-wrapper">
				<div class="popup-header">
					<h2>{{ $t('whatToBook') }}</h2>
					<div class="popup-close" @click="hideBookPopup">
						<font-awesome-icon icon="fa-light fa-times" size="1x" />
					</div>
				</div>
				<div class="popup-body">
					<div class="button-section">
						<book-button>
							<picture>
								<source :srcset="defaults[locale].homepage.popupImageRoomsWebp" type="image/webp" />
								<source :srcset="defaults[locale].homepage.popupImageRooms" />
								<img
									:src="defaults[locale].homepage.popupImageRooms"
									:alt="defaults[locale].homepage.popupImageRoomsAlt"
									loading="lazy"
								/>
							</picture>
							<span class="button book-now cta reserve-now">{{ $t('bookARoom') }}</span>
						</book-button>
					</div>
					<div class="button-section">
						<a href="/restaurant">
							<picture>
								<source
									:srcset="defaults[locale].homepage.popupImageRestaurantWebp"
									type="image/webp"
								/>
								<source :srcset="defaults[locale].homepage.popupImageRestaurant" />
								<img
									:src="defaults[locale].homepage.popupImageRestaurant"
									:alt="defaults[locale].homepage.popupImageRestaurantAlt"
									loading="lazy"
								/>
							</picture>
							<span class="button cta">{{ $t('bookATable') }}</span>
						</a>
					</div>
					<div class="button-section">
						<a href="/arrangementen">
							<picture>
								<source :srcset="defaults[locale].homepage.popupImagePackageWebp" type="image/webp" />
								<source :srcset="defaults[locale].homepage.popupImagePackage" />
								<img
									:src="defaults[locale].homepage.popupImagePackage"
									:alt="defaults[locale].homepage.popupImagePackageAlt"
									loading="lazy"
								/>
							</picture>
							<span class="button cta">{{ $t('bookAPackage') }}</span>
						</a>
					</div>
					<div class="button-section">
						<a href="/scooterverhuur">
							<picture>
								<source :srcset="defaults[locale].homepage.popupImageScooterWebp" type="image/webp" />
								<source :srcset="defaults[locale].homepage.popupImageScooter" />
								<img
									:src="defaults[locale].homepage.popupImageScooter"
									:alt="defaults[locale].homepage.popupImageScooterAlt"
									loading="lazy"
								/>
							</picture>
							<span class="button cta">{{ $t('bookAScooter') }}</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const route = useRoute();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

const showMobileNav = ref(false);

const showBookPopup = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const langmenu = computed(() => {
	return (page.value && page.value.langmenu) ?? [];
});

const toggleMobileNav = () => {
	showMobileNav.value = !showMobileNav.value;
};

const hideMobileNav = () => {
	showMobileNav.value = false;
};

const toggleBookPopup = () => {
	showBookPopup.value = !showBookPopup.value;
};

const hideBookPopup = () => {
	showBookPopup.value = false;
};

/*
const showLangNav = ref(false);
const toggleLangNav = () => {
	showLangNav.value = !showLangNav.value;
};
const closeLangNav = () => {
	showLangNav.value = false;
};
*/
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
	.nav-item:hover :deep(.submenu ul) {
		display: block;
	}
}

.mobile-only {
	display: none !important;
}

.main-nav {
	position: absolute;
	top: 42px;
	left: 0;
	right: 0;
	z-index: 1000;
	transition: background 0.3s ease-in-out;

	.nav-content ul {
		display: inline-flex;
	}

	&.fixed {
		position: fixed;
		top: 0;
	}

	ul li {
		display: inline-block;
		padding: 5px 0;
	}
}

.nav-bar {
	display: flex;
	align-items: center;
	padding: 3px 0;
	z-index: 9;
	position: relative;

	.buttons {
		display: flex;
		align-items: center;
		gap: 10px;

		.button {
			padding: 15px 25px;
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.icon {
			display: none;
			margin-right: 5px;
			font-size: 13px;
		}
	}
}

.book-popup {
	display: none;
	position: fixed;
	inset: 0;
	z-index: 1001;
	background: rgba(0 0 0 / 45%);

	&.show-book-popup {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.popup-wrapper {
		padding: 50px;
		width: 96%;
		max-width: 700px;
		margin: 0 auto;
		background: #fff;
	}

	.popup-close {
		text-align: right;
		cursor: pointer;
		font-size: 32px;
		font-weight: 700;
	}

	.popup-header {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 0 2%;
	}

	.popup-body {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
		padding: 25px 0 0;

		.button-section {
			width: 46%;
			margin: 2%;
		}

		.button {
			width: 100%;
		}
	}
}

.menu-button {
	cursor: pointer;
}

.nav-content {
	margin: auto;

	a {
		color: #fff;
		text-decoration: none;
		text-transform: uppercase;
		margin: 0 15px;
		letter-spacing: 1px;
		font-weight: 500;
		font-size: calc(var(--body-font-size) - 1px);

		&.active,
		&:hover {
			color: var(--button-color-hover);
		}
	}

	.menu-close {
		color: #fff;
		display: none;
		font-size: 30px;
		cursor: pointer;
		position: absolute;
		top: 15px;
		width: auto;
		right: 20px;
		padding: 0;
		margin: 0;
	}

	.lang-nav {
		margin: 25px auto;
		padding: 15px 0 0;
		border-top: 1px solid #6d6d6d;
		display: inline-block;
		max-width: 400px;

		.language {
			margin: 20px 0;

			.flag::before {
				content: '';
				width: 16px;
				position: relative;
				top: 3px;
				height: 16px;
				margin-right: 6px;
				display: inline-block;
			}

			.flag.en::before {
				background: url('~/assets/images/en.png') no-repeat;
			}

			.flag.nl::before {
				background: url('~/assets/images/nl.png') no-repeat;
			}

			.flag.de::before {
				background: url('~/assets/images/de.png') no-repeat;
			}

			.flag.fr::before {
				background: url('~/assets/images/fr.png') no-repeat;
			}

			.flag.es::before {
				background: url('~/assets/images/es.png') no-repeat;
			}
		}

		a {
			font-size: 15px;
			color: #fff;

			&.router-link-exact-active,
			&:hover {
				color: var(--cta-color);
			}
		}

		img {
			max-width: 16px;
			margin: -2px 5px 0 0;
			vertical-align: middle;
		}
	}
}

.lang-select {
	padding: 12px 15px;
	border-right: 1px solid var(--alternating-background-color);
	cursor: pointer;
	position: relative;
	text-transform: uppercase;
	color: #fff;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;

	i,
	svg {
		font-size: 13px;

		&:first-child {
			margin: 0 7px 0 0;
		}
	}

	a {
		color: #000;
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		place-content: center flex-start;
		gap: 8px;
		margin: 7px 0;
	}
}

.lang-wrapper {
	position: absolute;
	top: 35px;
	left: 3px;
	right: 3px;
	padding: 5px 9px;
	text-align: center;
	background: #fff;
	box-shadow: 0 18px 18px rgba(0 0 0 / 10%);
	border-radius: 3px;
	z-index: 90;

	.flag::before {
		content: '';
		width: 16px;
		position: relative;
		top: 0;
		height: 16px;
		display: inline-block;
	}

	.flag.en::before {
		background: url('~/assets/images/en.png') no-repeat;
	}

	.flag.nl::before {
		background: url('~/assets/images/nl.png') no-repeat;
	}

	.flag.de::before {
		background: url('~/assets/images/de.png') no-repeat;
	}

	.flag.fr::before {
		background: url('~/assets/images/fr.png') no-repeat;
	}

	.flag.es::before {
		background: url('~/assets/images/es.png') no-repeat;
	}

	.flag.no::before {
		background: url('~/assets/images/no.png') no-repeat;
	}
}

.fixed-lang-nav {
	display: none;
	color: var(--body-color);
	font-size: 14px;
	text-decoration: none;
	border-left: 1px solid;
	padding: 12px 15px 12px 35px;
}

.fixed .fixed-lang-nav {
	display: block;

	a {
		text-decoration: none;
	}
}

@media (max-width: 1280px) {
	.nav-bar a {
		font-size: 14px;
	}
}

@media (max-width: 1250px) {
	.mobile-only {
		display: flex !important;

		&.lang-nav {
			display: inline-block !important;
		}
	}

	.fixed .fixed-lang-nav,
	.fixed-lang-nav {
		display: none;
	}

	.nav-bar .buttons {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		align-items: center;

		.icon {
			display: unset;
		}

		.button {
			padding: 10px 25px;

			&.menu-button {
				background: none;
				padding: 0;
				margin: 0 10px 0 0;
				color: var(--body-color);

				svg {
					font-size: 24px;
				}
			}

			&.phone {
				display: none !important;
			}
		}
	}

	.main-nav {
		position: fixed !important;
		background: var(--primary-background-color);
		top: 0;
		box-shadow: 0 0 10px rgb(0 0 0 / 5%);

		&.show-mobile-nav {
			.nav-content ul {
				display: flex;
				flex-direction: column;
				justify-content: center;
				opacity: 1;
				visibility: visible;
				transition: opacity 0.3s ease-in-out;

				li:not(.menu-close) {
					width: 100%;
				}

				li:not(.lang-nav, .menu-close) {
					margin: 7px 0;

					a {
						font-size: 20px;
					}
				}
			}
		}

		ul {
			position: fixed;
			inset: 0;
			background: var(--body-color);
			text-align: center;
			padding: 20px 0;
			opacity: 0;
			visibility: hidden;
			overflow-y: scroll;
		}
	}

	.nav-content .menu-close {
		display: block;
	}
}

@media (max-width: 880px) {
	.nav-bar .buttons .button {
		padding: 16px;

		.icon {
			margin-right: 0;
			font-size: 16px;
		}

		span {
			display: none;
		}

		&.phone {
			display: flex !important;
		}
	}
}

@media (max-width: 700px) {
	.book-popup {
		.popup-body {
			.button-section {
				width: 98%;

				img {
					display: none;
				}
			}
		}
	}
}

@media (max-width: 580px) {
	.book-popup {
		.popup-wrapper {
			padding: 30px;

			h2 {
				font-size: 1.6em;
			}
		}
	}

	.nav-bar .buttons span {
		display: none;
	}
}
</style>
